import React, { Component } from "react";
import "./index.css";

class CourseOverview extends Component {
  render() {
    return (
      <div>
        <button className="course-overview-button">Live Courses</button>
      </div>
    );
  }
}

export default CourseOverview;
