import React, { Component } from "react";
import img from "../Store/banner.png";
import "./index.css";

class Banner extends Component {
  render() {
    return (
      <div className="banner-container">
        <img src={img} alt="Banner" className="banner-image" />
        {/* <div className="banner-stats">
          <div className="banner-stat">
            <img
              className="banner-icon"
              src="https://img.icons8.com/ios/50/teacher.png"
              alt="teacher"
            />
            <div>
              <h2 className="banner-number">30,000+</h2>
              <p className="banner-text">Total Learners</p>
            </div>
          </div>
          <div className="banner-stat">
            <img
              className="banner-icon"
              src="https://img.icons8.com/ios/50/teacher.png"
              alt="teacher"
            />
            <div>
              <h2 className="banner-number">30,000+</h2>
              <p className="banner-text">Total Learners</p>
            </div>
          </div>
          <div className="banner-stat">
            <img
              className="banner-icon"
              src="https://img.icons8.com/ios/50/teacher.png"
              alt="teacher"
            />
            <div>
              <h2 className="banner-number">30,000+</h2>
              <p className="banner-text">Total Learners</p>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

export default Banner;
