import React from "react";
import Blog from "./Blog";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Course1 from "./Components/CoursePages/Course1";
import Course2 from "./Components/CoursePages/Course2";
const App = () => {
  return (
    // <Router>
    <Routes>
      <Route path="/" element={<Blog />} exact />
      <Route path="/course1" element={<Course1 />} />
      <Route path="/course2" element={<Course2 />} />
      <Route path="*" element={<Blog />} />
    </Routes>
    // </Router>
  );
};

export default App;
