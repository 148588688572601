import React from "react";
import img from "../Store/LOGO SHADOW WITH BLACK TEXT.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faYoutube,
  faGoogle,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

import "./index.css";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer__social">
        <div className="footer__logo">
          <img src={img} alt="Logo" />
        </div>
        <div className="footer__icons">
          <a href="https://currencyveda.com">
            <FontAwesomeIcon
              style={{ color: "black" }}
              icon={faFacebook}
              size="2x"
            />
          </a>
          <a href="https://twitter.com/CurrencyVeda?t=gaTsdKIWmtybXQzorJSbfg&s=09">
            <FontAwesomeIcon
              style={{ color: "black" }}
              icon={faTwitter}
              size="2x"
            />
          </a>
          <a href="https://www.youtube.com/@currencyveda">
            <FontAwesomeIcon
              style={{ color: "black" }}
              icon={faYoutube}
              size="2x"
            />
          </a>
          <a href="https://currencyveda.com">
            <FontAwesomeIcon
              style={{ color: "black" }}
              icon={faGoogle}
              size="2x"
            />
          </a>
          <a href="https://www.instagram.com/currencyveda/?igshid=NTc4MTIwNjQ2YQ%3D%3D">
            <FontAwesomeIcon
              style={{ color: "black" }}
              icon={faInstagram}
              size="2x"
            />
          </a>
        </div>
        {/* <div className="footer__subscribe">Subscribe-icon</div> */}
      </div>
      <div className="footer__disclaimer">
        <h2>Disclaimer</h2>
        <p>
          The information provided on currencyveda.com, its associated blog
          sites, or any other site host should not be interpreted as investment
          advice in any form, including direct, indirect, or implied suggestions
          for trading stocks, investing in mutual funds, or any financial
          instruments.
        </p>
        <p>
          By accessing and using currencyveda.com, you agree to abide by the
          Terms and Conditions of Use. The articles and materials provided are
          based on information deemed reliable, but we do not guarantee their
          accuracy or completeness. They should not be solely relied upon for
          making investment decisions. We encourage investors to utilize the
          information as a resource for conducting their own research on
          featured companies, stocks, sectors, and markets. Please note that
          nothing published on this site should be construed as investment
          advice.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
