import React from "react";
import "./index.css";

class ContactInformation extends React.Component {
  render() {
    return (
      <div className="contact-infomain">
        <h1
          style={{
            margin: "0",
            marginBottom: "50px",
            fontSize: "46px",
            textAlign: "center",
            color: "darkblue",
          }}
        >
          All About Us!
        </h1>
        <br />

        <div className="contact-info">
          <div className="contact-info-item">
            <a href="mailto:Education@currencyveda.com">
              Email: Education@currencyveda.com
            </a>
          </div>
          <div className="contact-info-item">
            <a href="tel:01171906960">Phone: 011 7190 6960</a>
          </div>
          <div className="contact-info-item">
            <a href="tel:+917838134596">Mobile: +91 7838134596</a>
          </div>
          <div className="contact-info-item">
            <p>
              Address: PLOT No -30, T/F, H. NO D-30A, Dayanand Block, Nirman
              Vihar, Shakarpur, New Delhi, Delhi 110092
            </p>
          </div>
        </div>
        <div className="contact-info-item">
          <iframe
            title="Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.682126546306!2d77.2814073150829!3d28.63222298241623!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce3d4c5d3c6d3%3A0x8b9f6d7f5f5c1c4d!2sCurrency%20Veda!5e0!3m2!1sen!2sin!4v1634171189269!5m2!1sen!2sin"
            width="100%"
            height="200"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    );
  }
}

export default ContactInformation;
