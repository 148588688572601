import React from "react";
import Header from "./Components/Header";
import Banner from "./Components/Banner";
import CourseOverview from "./Components/CourseOverview";
import CourseCarousel from "./Components/CourseCarousel";
import JoiningBenefit from "./Components/JoiningBenefit";
import Footer from "./Components/Footer";
import Testimonial from "./Components/Testimonial";
import ContactForm from "./Components/ContactForm";
import Problems from "./Store/pain points.png";
import Solutions from "./Store/solutions.png";
import ContactInformation from "./Components/ContactInformation";
import ImageSection from "./Components/ImageSection";

function Blog() {
  return (
    <div>
      <Header />
      <div style={{ marginTop: "5px" }}>
        <Banner />
      </div>
      <div
        style={{
          marginTop: "100px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CourseOverview />
      </div>
      <div
        style={{
          marginTop: "65px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CourseCarousel />
      </div>
      {/* <div
        style={{
          marginTop: "65px",
          width: "98.8vw"
        }}
      >
        <img width="100%" src={Problems} alt="problems"/>
      </div>
      <div
        style={{
          marginTop: "0",
          width: "100%",
          textAlign: "center"
        }}
      >
        <img width="80%" src={Solutions} alt="solutions"/>
      </div> */}
      <div
        style={{
          marginTop: "90px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <JoiningBenefit />
      </div>
      <div>
        <Testimonial />
      </div>
      <div
        style={{
          marginTop: "90px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ContactForm />
      </div>

      <div
        style={{
          marginTop: "90px",
          display: "flex", // add this style
          justifyContent: "center", // add this style
        }}
      >
        <Footer />
      </div>
    </div>
  );
}

export default Blog;
