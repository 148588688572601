import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import ImageSection from "../ImageSection";

const Course2 = () => {
  const content1 = {
    heading: "Alpha Traders – Basic of Stock Market",
    points: [
      "What is Stock Market and Market Segment.",
      "Primary Market – Working of IPOs.",
      "Secondary Market – NSE/BSE Trading",
      "Stock Indices – Nifty / Sensex",
      "Long Trade / Short Trade (Short Selling) (Live Example)",
      "Pre-Open Session - Bulk Deal Block Deal",
      "Order Placement – Limit order / Market Order / SL Order.",
      "Advance Order – AMO / Bracket Orders/Cover Orders.",
      "Margin trading / leverage – MIS trade and Carry Trade.",
      "Circuit Breakers – On Stock / Index and Derivative.",
      "Trade Clearance and Settlement",
      "Broker and Demat Account.",
      "Factors Affecting the Market",
      "Commodities and Forex Market",
      "Linkages between Stock / Commodities and Forex",
      "Basic Terminologies of Market",
      "Contract Note",
      "Corporate Actions.",
    ],
  };
  const content2 = {
    heading: "Alpha Traders – Technical Analysis",
    points: [
      "Introduction to Technical Analysis",
      "Dow Theory and Technical Charts",
      "Trendline Analysis + Live Trading",
      "Support Resistance Traditional / Fractal Trading System",
      "Gap trading for Morning Trade.",
      "Pivot Points and CPR and Reversal Candles System",
      "Candlesticks Charting",
      "Chart Patterns (will be revised with Derivative trade)",
      "Moving Averages trend Analysis and trades on Nifty.",
      "Identifying Market Tops and Bottoms + Divergence",
      "Fibonacci Retracement and Projection",
      "Indicators and Oscillator",
      "Traditional – RSI / MACD/ Stochastic/ Bollinger Band",
      "Contemporary – VZONE/False Bar/Sinewave etc.",
      "Live trading in Demo and Real Account.",
    ],
  };

  const content3 = {
    heading: "Alpha Traders – Derivative Trading",
    points: [
      "Introduction to Derivative",
      "Introduction to Futures and Forwards Contracts",
      "Futures Contract Terminology",
      "Futures Margin + M2M",
      "Leverage and Futures Pricing",
      "Introduction to Options Contracts",
      "Detailed explanation of Call Put with simple example",
      "Option Buy and Option Selling",
      "Option Margin for Buying and Selling",
      "Option Premium and Moneyness of Option (ITM/OTM/ATM)",
      "Option Greeks Introduction",
      "Delta Introduction + Calculation",
      "Theta and Vega + Introduction to Option Strategies",
      "Straddle / Strangle / Covered Call + Protective Put",
      "Spread – Bull Call / Bear Put.",
      "Income Generation Strategies- Selling ITM Sell",
      "Trading Intraday Option – Nifty Bank Nifty",
      "Hedging with Delta",
      "Collar / Iron Condor/Butterfly / Ration Spread.",
      "Formulation and Interpretation of Strategies",
    ],
  };
  return (
    <div>
      <Header />
      <ImageSection type={1} content={content1} />
      <ImageSection type={2} content={content2} />
      <ImageSection type={1} content={content3} />
      <Footer />
    </div>
  );
};

export default Course2;
