import React from "react";
import categoryImg from "../Store/why should you join us.png";

function JoiningBenefit() {
  return (
    <div
      style={{
        background: "linear-gradient(#35d4ff, #b7e9fd)",
        color: "#fff",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        padding: "0",
      }}
    >
      <img className="banner-image" src={categoryImg} alt="why-join" />
    </div>
  );
}

export default JoiningBenefit;
