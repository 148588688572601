import React, { useState } from "react";
import "./index.css";

function ImageSection({type, content}) {

  return (
    <div
      className={type === 1 ? "image-section-1" : "image-section-2"}
      style={{
        display: "flex",
        alignItems:"center",
        padding: "90px"
      }}
    >
        <img src="https://cdn-icggj.nitrocdn.com/AphBmykuaGyxZijWArNhxcCiPzVdYZGT/assets/images/optimized/rev-62400df/files/DSC03177.png" alt="people"/>
        <div className="content">
            <h3>{content.heading}</h3>
            <ol>
                {content.points.map((point => {
                    return <li>{point}</li>;
                }))}
            </ol>
        </div>
    </div>
  );
}

export default ImageSection;
