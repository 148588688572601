import React from "react";

import { Autoplay, Pagination, Navigation } from "swiper";
import "./index.css";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const TestimonialSlider = ({ testimonials }) => {
  var x = window.matchMedia("(max-width: 500px)");
  // console.log(x.matches);
  return (
    <div
      style={{
        color: "white",
        height: "100%",
        padding: "19px 0px 0px 33px",
      }}
    >
      <Swiper
        modules={[Autoplay, Navigation]}
        spaceBetween={2}
        slidesPerView={x.matches ? 1 : 4}
        autoplay={{ delay: 5000 }}
        pagination={{ clickable: true }}
        navigation={!x.matches}
        // Pagination={
        //   x.matches ? { clickable: true, dynamicBullets: true } : false
        // }
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide key={index}>
            <div className="testimonials">
              <img
                src={testimonial.profilePic}
                alt="Profile"
                style={{
                  width: "75px",
                  height: "75px",
                  borderRadius: "50%",
                  marginBottom: "20px",
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <p
                  style={{
                    fontStyle: "italic",
                    fontSize: "14px",
                    marginBottom: "5px",
                  }}
                >
                  {testimonial.author}
                </p>
                <p style={{ marginBottom: "10px", height: "100px" }}>
                  {testimonial.quote}
                </p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {[...Array(testimonial.stars)].map((_, index) => (
                    <span
                      key={index}
                      style={{
                        color: "#FFD700",
                        fontSize: "20px",
                        marginRight: "5px",
                      }}
                    >
                      &#9733;
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default TestimonialSlider;
