import React from "react";
import TestimonialSlider from "./TestimonialSlider";
import profilePic1 from "../Store/Banner.jpg";
import profilePic2 from "../Store/Banner.jpg";
import profilePic3 from "../Store/Banner.jpg";

const Testimonial = () => {
  const testimonials = [
    {
      profilePic: profilePic1,
      stars: 4,
      author: "John Doe",
      quote:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus.",
    },
    {
      profilePic: profilePic2,
      stars: 5,
      author: "Jane Smith",
      quote:
        "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.",
    },
    {
      profilePic: profilePic3,
      stars: 3,
      author: "Bob Johnson",
      quote: "Nullam id dolor id nibh ultricies vehicula ut id elit.",
    },
    {
      profilePic: profilePic3,
      stars: 3,
      author: "Bob Johnson",
      quote: "Nullam id dolor id nibh ultricies vehicula ut id elit.",
    },
    {
      profilePic: profilePic3,
      stars: 3,
      author: "Bob Johnson",
      quote: "Nullam id dolor id nibh ultricies vehicula ut id elit.",
    },
    {
      profilePic: profilePic3,
      stars: 3,
      author: "Bob Johnson",
      quote: "Nullam id dolor id nibh ultricies vehicula ut id elit.",
    },
    {
      profilePic: profilePic3,
      stars: 3,
      author: "Bob Johnson",
      quote: "Nullam id dolor id nibh ultricies vehicula ut id elit.",
    },
    {
      profilePic: profilePic3,
      stars: 3,
      author: "Bob Johnson",
      quote: "Nullam id dolor id nibh ultricies vehicula ut id elit.",
    },
    {
      profilePic: profilePic3,
      stars: 3,
      author: "Bob Johnson",
      quote: "Nullam id dolor id nibh ultricies vehicula ut id elit.",
    },
    // {
    //   profilePic: profilePic3,
    //   stars: 3,
    //   author: "Bob Johnson",
    //   quote: "Nullam id dolor id nibh ultricies vehicula ut id elit.",
    // },
    // {
    //   profilePic: profilePic3,
    //   stars: 3,
    //   author: "Bob Johnson",
    //   quote: "Nullam id dolor id nibh ultricies vehicula ut id elit.",
    // },
    // {
    //   profilePic: profilePic3,
    //   stars: 3,
    //   author: "Bob Johnson",
    //   quote: "Nullam id dolor id nibh ultricies vehicula ut id elit.",
    // },
    // {
    //   profilePic: profilePic3,
    //   stars: 3,
    //   author: "Bob Johnson",
    //   quote: "Nullam id dolor id nibh ultricies vehicula ut id elit.",
    // },
    // Add more testimonials here
  ];

  return (
    <div>
      <h1
        style={{
          margin: "111px 0",
          fontSize: "46px",
          textAlign: "center",
          color: "#289dcc",
          marginBottom: "15px",
        }}
      >
        Testimonials
      </h1>
      <TestimonialSlider testimonials={testimonials} />
    </div>
  );
};

export default Testimonial;
