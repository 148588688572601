import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import ImageSection from "../ImageSection";

const Course1 = () => {
  const content = {
    heading: "Currency Trading Module",
    points: [
      "History of Currency Exchange",
      "What is Forex Market",
      "What are Major Currencies",
      "Cross Rate in Currency market",
      "Technical Analysis in Currency trading",
      "Impact of Market Economics and Event important for currency trading in India",
      "Currency Derivatives",
      "Options Trading in Currency Market",
      "Exchange Traded Currency Futures",
      "Advantages of Currency Futures",
      "Strategies on Currency Futures",
      "Risk Management",
      "Live Trading Practical’s on Currency Market",
    ],
  };

  return (
    <div>
      <Header />
      <ImageSection content={content} type={1} />
      <Footer />
    </div>
  );
};

export default Course1;
