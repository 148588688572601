import React from "react";
import icon from "../Store/icon.jpg";
import "./index.css";

function Header() {
  return (
    <>
      <div style={{width: '100%', textAlign: 'center', padding: '10px 0', backgroundColor: '#35d4ff', color: 'white', fontWeight: '700'}}>
        Learn basics to advance of the financial markets with Currencyveda
      </div>
      <header>
        <a href="https://currencyveda.com">
          <img
            style={{
              width: "191px",
              height: "100%",
            }}
            src={icon}
            alt="Logo"
          />
        </a>
        <nav>
          <a href="#">Courses</a>
          <a href="tel:+917838134596">+91-7838134596</a>
        </nav>
      </header>
    </>
  );
}

export default Header;
